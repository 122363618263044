@font-face {
    font-family: 'Gilroy';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Regular.woff") format("woff");
  }


  @font-face {
    font-family: 'Gilroy Black';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Black.woff") format("woff");
  }

  @font-face {
    font-family: 'Gilroy Bold';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Bold.woff") format("woff");
  }

  @font-face {
    font-family: 'Gilroy Extrabold';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Extrabold.woff") format("woff");
  }

  @font-face {
    font-family: 'Gilroy Heavy';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Heavy.woff") format("woff");
  }

  @font-face {
    font-family: 'Gilroy Light';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Light.woff") format("woff");
  }

  @font-face {
    font-family: 'Gilroy Medium';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Medium.woff") format("woff");
  }

  @font-face {
    font-family: 'Gilroy Semibold';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Semibold.woff") format("woff");
  }

  @font-face {
    font-family: 'Gilroy Thin';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-Thin.woff") format("woff");
  }

  @font-face {
    font-family: 'Gilroy Ultralight';
    font-style:  normal;
    font-weight: normal;
    src: url("./Gilroy-UltraLight.woff") format("woff");
  }