.page {
  max-width: 1920px;
  margin: 0 auto;
}

div,
section,
header,
footer {
  font-family: 'Gilroy';
}

li,
a {
  text-decoration: none !important;
  color: rgb(0, 0, 0) !important;
  list-style: none !important;
  transition: 0.2s ease-in-out;
}

a:hover {
  color: rgb(109, 109, 109) !important;
}

.btn-success {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.header__dropdown-container {
  display: none !important;
  align-items: center !important;
}

.header__dropdown {
  padding: 0 !important;
}

.modal-open {
  padding: 0px !important;
  overflow: visible !important;
}

.header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
}

.header__menu-mobile {
  width: 20px;
  height: 20px;
  align-self: center;
  display: none;
}

.header__logo {
  width: 100px;
}

.header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.header__logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header__logo-text-container {
  width: 100px;
  margin-left: 10px;
}

.header__logo-text {
  margin: 0;
  font-size: 30px;
  font-family: 'Gilroy Bold';
}

.header__nav {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin: 0;
  font-family: 'Gilroy Bold';
  font-size: 18px;
}

.container {
  max-width: 1920px !important;
  padding: 0 !important;
}

.slider__carousel .carousel-caption {
  left: 5% !important;
  top: 24% !important;
  bottom: 0 !important;
  right: 0 !important;
  text-align: left !important;
  width: 70%;
  z-index: 50;
}

.slider__carousel .carousel-indicators {
  z-index: 55 !important;
}


.slider__title {
  font-size: 150px;
  font-family: 'Gilroy Black';
}

.slider__subtitle {
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
}

.slider__button {
  margin-top: 50px;
  width: 300px;
  font-size: 30px !important;
  font-family: 'Gilroy Black';
  text-shadow: 0 0 0.925em rgb(255, 255, 255);
  box-shadow: 0 0 0.5em 0 rgb(0, 89, 255);
}

.slider__carousel .carousel-control-next-icon,
.slider__carousel .carousel-control-prev-icon {
  display: none !important;
}

.about {
  margin-top: 100px;
}

.about__text-blue {
  color: rgb(14 111 253);
}

.about__container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.about__title {
  margin-bottom: 25px !important;
}

.about__text {
  width: 100%;
  font-size: 20px;
}

@media screen and (max-width: 750px) {
  .about__text {
    font-size: 14px;
  }
}


.services {
  width: 90%;
  margin: 100px auto;
}

.title {
  font-size: 55px;
  font-family: 'Gilroy Black';
  margin: 0;
}

.services__container {
  margin: 50px auto;
  display: grid;
  grid-template-columns: repeat(2, 850px);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
}

.services__item {
  position: relative;
}

.services__item-container {
  position: absolute;
  top: 110px;
  left: 50px;
  color: rgb(255, 255, 255);
  width: 63%;
}

.services__item-title {
  font-family: 'Gilroy Black';
  font-size: 50px;
}

.services__text {
  font-size: 20px;
}

.services__button {
  font-family: 'Gilroy Black';
  text-shadow: 0 0 0.925em rgb(255, 255, 255);
  box-shadow: 0 0 0.5em 0 rgb(0, 89, 255);
}

.btn:focus {
  text-shadow: 0 0 0.925em rgb(255, 255, 255) !important;
  box-shadow: 0 0 0.5em 0 rgb(0, 89, 255) !important;
}

.services__image {
  width: 850px;
  border-radius: 12px;
}

.catalog {
  margin-top: 100px;
}

.catalog__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.catalog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 950px;
}

.catalog__button {
  width: 200px !important;
  height: 30px !important;
  font-size: 17px !important;
  font-family: 'Gilroy Medium' !important;
  padding: 0 !important;
  margin-top: 10px;
}

.catalog__grid {
  margin: 50px auto;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(4, 330px);
  grid-column-gap: 120px;
  grid-row-gap: 120px;
}

.catalog__item {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  border-radius: 12px;
  cursor: pointer;
}

.catalog__item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  justify-content: space-between;
  height: 514px;
}

.catalog__item-exit-active {
  animation: fade-out 500ms forwards;
}

.catalog__item-enter-active {
  animation: fade-out 500ms forwards;
}


.catalog__item-title {
  margin-top: 30px;
  text-align: center;
  width: 90%;
  height: 67px;
  font-size: 25px;
}

.catalog__item-img {
  margin-top: 30px;
  width: 180px;
  height: 218px;
}

.catalog__item-price {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  width: 108px;
  align-self: baseline;
}

.catalog__not-found {
  display: flex;
  width: 50%;
  margin: 0 auto;
  margin-top: 50px;
  align-items: center;
  flex-direction: column;
}

.catalog__not-found-text {
  font-size: 20px;
}

.more-button {
  width: 200px !important;
  margin: 0 auto !important;
  margin-top: 100px !important;
}

.delivery {
  margin-top: 100px;
}

.delivery__subtitle {
  margin-top: 20px;
}

.delivery__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.delivery__companies {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery__image {
  align-self: center;
}

.delivery__image-mobile {
  display: none;
}

.delivery__image-single {
  width: 600px;
  margin-top: 50px;
}

.portfolio-container {
  width: 90%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
}

.portfolio__grid {
  display: grid;
  grid-template-columns: repeat(4, 300px);
  grid-column-gap: 113px;
  grid-row-gap: 113px;
  justify-content: center;
  margin-top: 50px;
}

.portfolio__image {
  width: 300px;
  cursor: pointer;
}

.uniform {
  margin-top: 100px;
}

.uniform__container {
  width: 90%;
  margin: 0 auto;
}

.uniform__item {
  margin-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.uniform__image {
  width: 48%;
  border-radius: 12px;
}

.uniform__container-text {
  width: 48%;
}

.uniform__title {
  font-size: 40px;
  font-family: 'Gilroy Black';
}

.uniform__text {
  font-size: 30px;
}

.uniform__button {
  font-family: 'Gilroy Black';
  text-shadow: 0 0 1.025em rgb(255, 255, 255);
  box-shadow: 0 0 0.3em 0 rgb(0, 89, 255);
}

.uniform__cloth {
  display: grid;
  grid-template-columns: repeat(2, 610px);
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.uniform__cloth-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.uniform__cloth-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
}

.uniform__cloth-container-text {
  width: 500px;
}

.uniform__cloth-title {
  font-family: 'Gilroy bold';
}

.uniform__slider {
  width: 45% !important;
}

.uniform__carousel .carousel-indicators {
  display: none !important;
}

.contacts {
  margin-top: 100px;
}

.contacts__container {
  width: 90%;
  margin: 0 auto;
}

.contacts__subtitle {
  margin-top: 20px;
  margin-bottom: 30px;
}


.footer {
  margin-top: 100px;
  background-color: #363636;
  color: rgb(255, 255, 255);
}

.footer__container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 100px;
}

.footer__item {
  width: 320px;
}

.footer__bottom {
  background-color: #000000;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__title {
  font-size: 40px;
  font-family: 'Gilroy Black';
}

.footer__text {
  font-size: 20px;
}


.footer__icon {
  width: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.footer__bottom-text {
  margin: 0;
  width: 80%;
  text-align: center;
}



.portfolio__modal {
  position: fixed;
  background-color: rgba(0, 0, 0, .4);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
  z-index: 150;
}

.portfolio__modal.active {
  opacity: 1;
  pointer-events: all;
}

.portfolio__modal-content {
  border-radius: 12px;
  width: 400px;
  transform: scale(0.5);
  transition: 0.4s all;
}

.portfolio__modal-content.active {
  transform: scale(1);
}

.portfolio__modal-close {
  display: none;
  width: 40px;
  position: relative;
  left: 160px;
}

@media screen and (max-width: 680px) {
  .portfolio__modal-close {
    display: flex;
  }
}

@media screen and (max-width: 390px) {
  .portfolio__modal-close {
    display: flex;
    left: 95px;
  }
}

@media screen and (max-width: 680px) {
  .portfolio__modal {
    flex-direction: column;
  }
}


.uniform-modal__container {
  display: flex;
  justify-content: space-between;
}

.uniform-modal__image {
  width: 400px;
  height: 300px;
  border-radius: 12px;
}

.uniform-modal__text {
  width: 350px;
}

.modal-text {
  margin: 5px;
}

.modal-phone {
  color: rgb(0, 89, 255);
}

.catalog-modal__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 20px auto;
}

.catalog-modal__image {
  display: flex;
  width: 180px;
  margin: 0 auto;
}

.catalog-modal__info {
  width: 40%;
}

.catalog-modal__price {
  font-size: 23px;
  font-weight: 800;
}

.catalog-modal__image-container {
  width: 55%;
  justify-content: center;
}

.catalog-modal__title {
  font-size: 20px;
  font-weight: 800;
  margin-top: 50px;
}

.catalog-modal__price-count {
  color: #4e8cff;
}

.contacts__subtitle-phone {
  margin-top: 0;
}

.contacts__subtitle-adress {
  margin-bottom: 10px;
}

.catalog-modal__cloth {
  font-size: 20px;
  font-weight: 800;
}

.catalog-modal__cloth span {
  font-weight: 100;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



@media screen and (max-width: 1910px) {
  .catalog__grid {
    grid-template-columns: repeat(3, 330px);
    grid-column-gap: 100px;
    grid-row-gap: 100px;
  }
}

@media screen and (max-width: 1850px) {
  .slider__carousel .carousel-caption {
    top: 20% !important;
  }
}

@media screen and (max-width: 1650px) {
  .slider__carousel .carousel-caption {
    top: 16% !important;
  }
}

@media screen and (max-width: 1550px) {
  .slider__carousel .carousel-caption {
    top: 12% !important;
  }
}

@media screen and (max-width: 1400px) {
  .catalog__grid {
    grid-template-columns: repeat(3, 330px);
    grid-column-gap: 80px;
    grid-row-gap: 80px;
  }
}

@media screen and (max-width: 1600px) {
  .portfolio__grid {
    grid-template-columns: repeat(3, 300px);
  }
}

@media screen and (max-width: 1440px) {
  .slider__title {
    font-size: 120px;
  }
}

@media screen and (max-width: 1440px) {
  .slider__subtitle {
    font-size: 40px;
  }
}

@media screen and (max-width: 1440px) {
  .slider__button {
    margin-top: 20px;
    width: 250px;
    font-size: 30px !important;
  }
}

@media screen and (max-width: 1440px) {
  .slider__carousel .carousel-caption {
    top: 19% !important;
  }
}

@media screen and (max-width: 1440px) {
  .delivery__image {
    width: 400px;
  }
}

@media screen and (max-width: 1730px) {
  .services__container {
    grid-template-columns: repeat(2, 600px);
  }
}

@media screen and (max-width: 1730px) {
  .services__image {
    width: 600px;
  }
}


@media screen and (max-width: 1730px) {
  .services__item-container {
    top: 62px;
  }
}

@media screen and (max-width: 1730px) {
  .services__item-title {
    font-size: 35px;
  }
}

@media screen and (max-width: 1730px) {
  .services__text {
    font-size: 20px;
  }
}

@media screen and (max-width: 1440px) {
  .header__nav {
    width: 50%;
  }
}



@media screen and (max-width: 1280px) {
  .uniform__cloth {
    grid-template-columns: repeat(2, 460px);
  }
}

@media screen and (max-width: 1280px) {
  .uniform__cloth-container-text {
    width: 350px;
  }
}

@media screen and (max-width: 1280px) {
  .uniform__text {
    font-size: 23px;
  }
}


@media screen and (max-width: 1270px) {
  .services__container {
    grid-template-columns: repeat(2, 400px);
  }
}

@media screen and (max-width: 1270px) {
  .services__image {
    width: 400px;
  }
}

@media screen and (max-width: 1270px) {
  .services__item-container {
    top: 45px;
  }
}

@media screen and (max-width: 1270px) {
  .services__item-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 1270px) {
  .services__text {
    font-size: 11px;
  }
}

@media screen and (max-width: 1270px) {
  .services__button {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1270px) {
  .catalog__grid {
    grid-template-columns: repeat(2, 330px);
    grid-column-gap: 100px;
    grid-row-gap: 100px;
  }
}

@media screen and (max-width: 1270px) {
  .slider__carousel .carousel-caption {
    top: 10% !important;
  }
}


@media screen and (max-width: 1270px) {
  .delivery__image {
    width: 300px;
  }
}

@media screen and (max-width: 1270px) {
  .delivery__image-single {
    display: none;
  }
}

@media screen and (max-width: 1270px) {
  .delivery__image-mobile {
    display: block;
  }
}

@media screen and (max-width: 1270px) {
  .delivery__companies {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    justify-content: center;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
  }
}

@media screen and (max-width: 1270px) {
  .portfolio__grid {
    grid-template-columns: repeat(2, 300px);
  }
}

@media screen and (max-width: 1270px) {
  .footer__container {
    width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .catalog__header {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .header__nav {
    width: 70%;
  }
}


@media screen and (max-width: 1200px) {
  .slider__title {
    font-size: 87px;
  }
}

@media screen and (max-width: 1200px) {
  .slider__subtitle {
    font-size: 29px;
    line-height: 23px;
  }
}


@media screen and (max-width: 1200px) {
  .slider__button {
    margin-top: 20px;
    width: 144px;
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1200px) {
  .slider__carousel .carousel-caption {
    top: 19% !important;
  }
}

@media screen and (max-width: 1000px) {
  .uniform__cloth {
    grid-template-columns: repeat(1, 610px);
  }
}

@media screen and (max-width: 1000px) {
  .uniform__cloth-container-text {
    width: 500px;
  }
}

@media screen and (max-width: 900px) {
  .slider__carousel .carousel-caption {
    top: 9% !important;
  }
}

@media screen and (max-width: 990px) {
  .catalog-modal__price {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .catalog-modal__container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .catalog-modal__image-container {
    order: 2;
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .catalog-modal__info {
    order: 1;
    width: 100%;
  }
}

@media screen and (max-width: 840px) {
  .services__container {
    grid-template-columns: repeat(1, 600px);
  }
}

@media screen and (max-width: 840px) {
  .services__image {
    width: 600px;
  }
}

@media screen and (max-width: 840px) {
  .services__item-title {
    font-size: 37px;
  }
}

@media screen and (max-width: 840px) {
  .services__text {
    font-size: 19px;
  }
}

@media screen and (max-width: 840px) {
  .services__button {
    font-size: 21px !important;
  }
}

@media screen and (max-width: 840px) {
  .services__item-container {
    top: 58px;
  }
}

@media screen and (max-width: 840px) {
  .catalog__grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
}

@media screen and (max-width: 960px) {
  .catalog__header {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 840px) {
  .title-mob {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 840px) {
  .portfolio__grid {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
}

@media screen and (max-width: 840px) {
  .footer__bottom-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 840px) {
  .uniform__item {
    flex-direction: column;
  }
}

@media screen and (max-width: 840px) {
  .uniform__slider {
    width: 100% !important;
  }
}

@media screen and (max-width: 840px) {
  .uniform__container-text {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media screen and (max-width: 840px) {
  .slider__button {
    margin-top: 11px;
    width: 109px;
    font-size: 15px !important;
  }
}

@media screen and (max-width: 840px) {
  .slider__title {
    font-size: 80px;
  }
}

@media screen and (max-width: 840px) {
  .slider__subtitle {
    font-size: 24px;
    line-height: 20px;
  }
}

@media screen and (max-width: 840px) {
  .slider__carousel .carousel-caption {
    top: 15% !important;
  }
}

@media screen and (max-width: 840px) {
  .header__logo {
    width: 52px;
  }
}

@media screen and (max-width: 840px) {
  .header__nav-text {
    font-size: 11px;
  }
}

@media screen and (max-width: 840px) {
  .header__nav {
    width: 53%;
  }
}

@media screen and (max-width: 840px) {
  .header__logo-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 840px) {
  .carousel-indicators button {
    width: 14px !important;
    height: 2px !important;
  }
}

@media screen and (max-width: 760px) {
  .catalog__grid {
    grid-template-columns: repeat(1, 330px);
  }
}

@media screen and (max-width: 760px) {
  .slider__title {
    font-size: 70px;
  }
}

@media screen and (max-width: 760px) {
  .slider__subtitle {
    font-size: 21px;
  }
}

@media screen and (max-width: 690px) {
  .slider__carousel .carousel-caption {
    top: 7% !important;
    width: 80%;
  }
}

@media screen and (max-width: 610px) {
  .services__image {
    width: 300px;
  }
}

@media screen and (max-width: 610px) {
  .services__container {
    grid-template-columns: repeat(1, 300px);
  }
}

@media screen and (max-width: 610px) {
  .services__item-title {
    font-size: 19px;
  }
}

@media screen and (max-width: 610px) {
  .services__text {
    font-size: 11px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 610px) {
  .services__item-container {
    top: 24px;
    left: 24px;
    width: 74%;
  }
}

@media screen and (max-width: 610px) {
  .services__button {
    font-size: 10px !important;
    padding: 0.2rem 0.8rem !important;
  }
}

@media screen and (max-width: 375px) {
  .catalog__grid {
    grid-template-columns: repeat(1, 300px);
  }
}

@media screen and (max-width: 680px) {
  .delivery__companies {
    grid-template-columns: repeat(1, 300px);
  }
}

@media screen and (max-width: 680px) {
  .portfolio__grid {
    grid-template-columns: repeat(1, 300px);
  }
}

@media screen and (max-width: 680px) {
  .footer__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 680px) {
  .footer__bottom-text {
    width: 90%;
    font-size: 8px;
  }
}

@media screen and (max-width: 680px) {
  .footer__item {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 610px) {
  h2 {
    width: 100%;
  }
}

@media screen and (max-width: 610px) {
  section {
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 610px) {
  .uniform__cloth {
    grid-template-columns: repeat(1, 450px);
  }
}

@media screen and (max-width: 610px) {
  .uniform__cloth-container-text {
    width: 325px;
  }
}

@media screen and (max-width: 610px) {
  .header__nav {
    width: 70%;
  }
}

@media screen and (max-width: 610px) {
  .slider__title {
    font-size: 56px;
  }
}

@media screen and (max-width: 610px) {
  .slider__subtitle {
    font-size: 17px;
    line-height: 8px;
  }
}

@media screen and (max-width: 610px) {
  .slider {
    margin-top: 0 !important;
  }
}


@media screen and (max-width: 490px) {
  .slider__title {
    font-size: 30px;
  }
}


@media screen and (max-width: 490px) {
  .slider__subtitle {
    font-size: 13px;
    line-height: 5px;
  }
}

@media screen and (max-width: 490px) {
  .slider__button {
    margin-top: 10px;
    width: 100px;
    font-size: 11px !important;
    padding: 0.3rem 0.3rem !important;
  }
}

@media screen and (max-width: 490px) {
  .slider__carousel .carousel-caption {
    top: 13% !important;
  }
}

@media screen and (max-width: 460px) {
  .uniform__cloth {
    grid-template-columns: repeat(1, 320px);
  }
}

@media screen and (max-width: 460px) {
  .uniform__cloth-container-text {
    width: 200px;
  }
}


@media screen and (max-width: 375px) {
  .carousel-caption {
    left: 7% !important;
    top: 1px !important;
  }
}

@media screen and (max-width: 375px) {
  .carousel-caption {
    left: 1% !important;
    top: 1px !important;
  }
}

@media screen and (max-width: 375px) {
  .slider__title {
    font-size: 28px;
  }
}


@media screen and (max-width: 375px) {
  .slider__subtitle {
    font-size: 11px;
    line-height: 12px;
  }
}

@media screen and (max-width: 375px) {
  .slider__button {
    margin-top: 0;
    width: 85px;
    font-size: 9px !important;
    padding: 0.3rem 0.6rem;
  }
}

@media screen and (max-width: 375px) {
  .carousel-indicators {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 350px) {
  .slider__carousel .carousel-caption {
    top: 3% !important;
  }
}

@media screen and (max-width: 520px) {
  .header__nav {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .btn:focus {
    text-shadow: none !important;
    box-shadow: none !important;
  }
}

@media screen and (max-width: 520px) {
  .header__dropdown-container {
    display: flex !important;
  }
}

@media screen and (max-width: 520px) {
  .header__menu-mobile {
    display: block;
  }
}

@media screen and (max-width: 355px) {
  .catalog__grid {
    grid-template-columns: repeat(1, 290px);
  }
}

@media screen and (max-width: 375px) {
  .title {
    font-size: 43px;
  }
}