@media screen and (max-width: 1700px) {
    h2 {
        font-size: 50px !important;
    }
}

@media screen and (max-width: 1280px) {
    h2 {
        font-size: 40px !important;
    }
}

@media screen and (max-width: 630px) {
    h2 {
        font-size: 30px !important;
    }
}